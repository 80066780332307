<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-21 16:12:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-11 17:06:52
-->
<template>
  <div>
    <table-list
      title="管理员角色"
      :loading="loading"
      :data="adminList"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @size-change="handleSizeChange"
      @selectionChange="handleSelectionChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <el-dialog
      :close-on-click-modal="false"
      width="30%"
      :visible.sync="dialogVisible"
      title="确定更换管理员"
    >
      <div class="identity">
        <div class="userNews">
          <div class="adminNews">
            <div class="headPic"><img :src="NowSuperAdminDetail.superHeadPic" alt="" /></div>
            <div class="adminInfo">
              <div class="adminName">{{ NowSuperAdminDetail.superUserName }}</div>
              <div>{{ NowSuperAdminDetail.superPhone }}</div>
            </div>
          </div>
          <div class="change">更换为</div>
          <div class="adminNews">
            <div class="headPic">
              <img :src="superAdminDetail.headPic" alt="" />
            </div>
            <div class="adminInfo">
              <div class="adminName">{{ superAdminDetail.userName }}</div>
              <div>{{ superAdminDetail.phone }}</div>
            </div>
          </div>
        </div>
        <div class="phone">
          手机号：{{ NowSuperAdminDetail.superPhone }}
          <el-button
            type="primary"
            size="medium"
            class="codeBtn"
            :class="{ gray: count > 0 }"
            :disabled="count > 0"
            @click="getCode"
            >{{ count > 0 ? count + 's' : '获取验证码' }}</el-button
          >
        </div>
        <div class="code">
          短信验证码：
          <el-input
            v-model="phone"
            placeholder="请输入短信验证码"
            class="input-width"
            oninput="value=value.replace(/[^\d]/g,'')"
            clearable
          ></el-input>
        </div>
        <div class="submit">
          <el-button type="primary" size="medium" @click="dialogSubmit">确定</el-button>
          <el-button size="medium" class="cancel" @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '确定',
    type: 'primary',
    method: _this.handleSubmit,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'nickName',
    label: '管理员昵称',
  },
  {
    prop: 'userName',
    label: '用户名',
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
  {
    prop: 'rolesName',
    label: '管理员角色',
  },
  {
    prop: 'createTime',
    label: '添加时间',
  },
]

import TableList from '@/components/TableList'
import { getAdminList, getAdminCode, getChangeAdmin, getSearchSuperAdmin } from '@/api/college'
import to from 'await-to'
export default {
  name: 'CollegeAdmin',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      adminList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      select: [],
      queryParams: {},
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
      phone: '',
      count: '',
      dialogVisible: false,
      adminCode: '',
      superAdminDetail: {},
      NowSuperAdminDetail: {},
    }
  },
  created() {
    this.getAdminListData()
    this.getSearchSuperAdminData()
  },
  methods: {
    async getAdminListData() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        getAdminList({ ...this.pager, ...this.queryParams, clientCode: this.clientCode }),
      )
      this.loading = false
      if (err) return this.$message.error(err.msg)
      this.adminList = res.data.list
    },
    async getSearchSuperAdminData() {
      const [res, err] = await to(getSearchSuperAdmin())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.NowSuperAdminDetail = res.data
    },
    handleSubmit() {
      if (this.select.length == 0) return this.$message.warning('请选择更换的管理员')
      let arr = []
      this.select.map(v => arr.push(v))
      if (this.select.length > 1) return this.$message.warning('请选择一个更换的超级管理员')
      this.superAdminDetail = arr[0]
      this.dialogVisible = true
    },
    async dialogSubmit() {
      if (this.NowSuperAdminDetail.superPhone == this.superAdminDetail.phone)
        return this.$message.warning('不可更换自己为超级管理员')
      if (this.phone == '') return this.$message.warning('请输入验证码')
      const [, err] = await to(
        getChangeAdmin({ userId: this.select[0].userId, verifyCode: this.phone }),
      )
      if (err) return this.$message.error(err.msg)
      this.$message.success('更换成功')
      this.dialogVisible = false
      this.$router.push('accountsManage')
    },
    async getAdminCodeData() {
      const [, err] = await to(getAdminCode())
      if (err) return this.$message.error(err.msg)
    },
    getCode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        this.getAdminCodeData()
      }
    },
    handleSelectionChange(val) {
      this.select = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getAdminListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getAdminListData()
    },
  },
}
</script>
<style scoped lang="scss">
.identity {
  font-size: 14px;
  color: #606266;
  display: flex;
  flex-direction: column;
  align-items: center;
  .userNews {
    display: flex;
    align-items: center;
    .adminNews {
      width: 200px;
      height: 94px;
      border-radius: 10px;
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
      .adminInfo {
        margin-left: 10px;
        .adminName {
          margin-bottom: 20px;
        }
      }
    }
    .change {
      margin: 0 20px;
      font-size: 14px;
      color: #606266;
    }
  }
  .phone {
    margin-top: 40px;
    .codeBtn {
      margin-left: 22px;
    }
  }
  .code {
    margin: 14px 0 106px 0;
  }
  .cancel {
    margin-left: 48px;
  }
}
.input-width {
  width: 184px;
  height: 40px;
}
.gray {
  background-color: #909399 !important;
}
</style>
